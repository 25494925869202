// 全局使用的sass变量和函数，
// config/webpack.config.js文件 找到exclude: sassModuleRegex 
// 使用 sass-resources-loader 依赖引入
$Header_height: 85px;
$Ratio_small: 0.55;
$Ratio_medium: 0.6;
$Ratio_large: 0.65;

$Screen_small: 1280px;
$Screen_medium_start: 1281px;
$Screen_medium_end: 1679px;
$Screen_large: 1680px;

@function vw($px) {
  @return ($px / 1920) * 100vw;
}

@function vh($px) {
  @return ($px / 1080) * 100vh;
}

@function vh700($px) {
  @return ($px / 700) * 100vh;
}



// // PX 转 rem
// @function px2Rem($px, $base-font-size: 19.2px) {
//   @if (unitless($px)) { //有无单位
//     @return ($px / 19.2) * 1rem;
//   } @else if (unit($px) == em) {
//     @return $px;
//   }
//   @return ($px / $base-font-size) * 1rem;
// }

// 屏幕在480px到768之间（主要是手机屏幕）
// 屏幕在768px到1023之间（小屏幕-pad）
// 屏幕在1024px到1199之间（中屏幕电脑）
// 屏幕大于1200排序（大屏幕电脑）

// PC端************
// 按屏幕宽度大小排序（主流：1024 1280 1440 1920）
// 分辨率   比例 | 设备尺寸
// 1024*600            （常见8、9寸电脑使用)
// 1024*768            （常见10.4、12.1、14.1、15寸电脑使用）
// 1280*1024            (常见14.1、15寸电脑使用）
// 1280*800            （常见10.8、12.1、15.4寸电脑使用）
// 1280*854            （不常见)
// 1366*768            （常见15.2寸电脑使用)（主流）
// 1440*1050            (常见15、16.1寸电脑使用)
// 1440*900            （苹果17寸电脑)
// 1600 * 900            (非主流)
// 1600*1200            (常见15、16.1寸电脑使用)
// 1600*1024            (不常见)
// 1680*1050            (常见15.4、20寸电脑使见)
// 1920*1080            (主流)
// 1920*1200            (常见20寸电脑使见)


// rem 单位换算：定为 19.20px 只是方便运算，1920px-19.20px、1080-10.px、1080px-108px，如此类推
$vw_fontsize: 19.20; // 设计稿(1920*1080)根元素大小基准值
@function rem($px) {
    // @return if($px <= 1, 1px, ($px / $vw_fontsize ) * 1rem);
    @return ($px / $vw_fontsize ) * 1rem;
}

// 根元素大小使用 vw 单位
$vw_design: 1920;
html {
    font-size: ($vw_fontsize / $vw_design) * 100vw; 
    // 同时，通过Media Queries 限制根元素最大最小值
    @media screen and (max-width: 1280px) {
        font-size: 12.80px;
    }
    @media screen and (min-width: 1920px) {
        font-size: 19.20px;
    }
}

// body 也增加最大最小宽度限制，避免默认100%宽度的 block 元素跟随 body 而过大过小
body {
    max-width: 1920px;
    // min-width: 1080px;
}
@mixin styling($Ratio){
  .Enter_Sibu{
    width: 100%;
    overflow: hidden;
    #banner_img{
      width: rem(1330);
      height: rem(400);
      background-image: url('https://sibu-website.oss-cn-shenzhen.aliyuncs.com/pc/banner%402x.png?x-oss-process=image/resize,w_1330,m_lfit');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      margin: 0 auto;
    }
    .content{
      width: 100%;
      height: 100%;
      padding-top: 85px*$Ratio;
      .Anchor_content{
        width: 100%;
        position: relative;
        .toFixed{
          position: fixed;
          top: calc(85px*#{$Ratio});
        }
        .toAbsolute{
          position: fixed;
          top: calc(85px*#{$Ratio} + #{rem(100)});
        }
        // .toAbsolute{
        //   position: absolute;
        //   top: calc(85px*$Ratio + #{rem(100)});
        // }
        .anchors{
          z-index: 99;
          background-color: #ededed;
          .item{
            width: 200px;
            height: 60px;
            line-height: 60px;
            background: #FFFFFF;
            padding: 0;
            margin-bottom: 5px*$Ratio;
            font-size: 24px*$Ratio;
            color: #999999;
            text-align: center;
            &:last-child{
              margin-bottom: 0;
            }
          }
          .active{
            background: #BE3D59;
            color: #FFFFFF;
          }
        }
        .item_content{
          width: rem(1330);
          margin-bottom: rem(45);
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 0 auto;
          .richText{
            width: 100%;
            margin-bottom: rem(100);
            img{
              max-width: 100%;
            }
          }
          // 企业荣誉
          .company_honors{
            width: 100%;
            margin-bottom: rem(100);
            .company_honor_item{
              display: flex;
              flex-direction: row;
              align-items: center;
              margin-bottom: rem(35);
              &:last-child{
                margin-bottom: 0;
              }
              .colorBlock{
                width: rem(20);
                height: rem(20);
                background: #BE3D59;
                border-radius: 50%;
              }
              .desc{
                flex: 1;
                margin-left: rem(20);
                font-size: 28px*$Ratio;
                color: #000000;
                line-height: 37px*$Ratio;
              }
            }
          }
          // 董事长荣誉
          .chairman_honors{
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding-left: rem(120);
            padding-right: rem(120);
            margin-bottom: rem(100);
            .avatar{
              width: rem(450);
              border-radius: rem(10);
              overflow: hidden;
              img{
                width: 100%;
                height: 100%;
              }
            }
            .others{
              margin-left: rem(100);
              flex: 1;
              .name{
                margin-top: rem(70);
                margin-bottom: rem(26);
                font-size: 36px*$Ratio;
                font-family: PingFangSC-Semibold;
                font-weight: 600;
                color: #000000;
              }
              .honors{
                width: 100%;
                .honor_item{
                  font-size: 28px*$Ratio;
                  color: #000000;
                  line-height: 37px*$Ratio;
                  margin-top: 5px*$Ratio;
                }
              }
            }
          }
          // 企业大事记
          .enterprise_deeds{
            width: 100%;
            position: relative;
            margin-bottom: rem(100);
            .midLine{
              position: absolute;
              width: 1px;
              height: 100%;
              background: #000000;
              left: 50%;
            }
            .deeds_item{
              display: flex;
              align-items: center;
              width: 100%;
              &:nth-child(odd){
                flex-direction: row-reverse;
                .date{
                  padding-left: rem(30);
                }
                .deeds{
                  margin-right: rem(73);
                }
              }
              &:nth-child(even){
                flex-direction: row;
                .date{
                  padding-right: rem(30);
                  text-align: right;
                }
                .deeds{
                  margin-left: rem(73);
                }
              }
              .date{
                width: 50%;
                font-size: 28px*$Ratio;
                font-weight: 600;
                color: #000000;
              }
              .colorBlock{
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                width: rem(20);
                height: rem(20);
                background: #BE3D59;
                border-radius: 50%;
                margin: auto;
              }
              .deeds{
                max-width: rem(264);
                font-size: 24px*$Ratio;
                color: #000000;
                line-height: 35px*$Ratio;
                margin: 0 rem(15);
              }
              .pic{
                width: rem(311);
                height: rem(176);
                border-radius: rem(3);
              }
            }
          }
          // 品牌产品
          .brand_products{
            margin: 0 auto;
            margin-bottom: rem(70);
            .category{
              display: flex;
              flex-direction: row;
              align-items: center;
              border-radius: rem(10);
              box-shadow: 0 rem(11) rem(35) 0 rgba(0, 0, 0, 0.08);
              overflow: hidden;
              margin: 0 auto rem(20);
              background: -webkit-linear-gradient(left, #BE3D59 rem(70),#FFF rem(70), #FFF 100%);
              .label{
                width: rem(70);
                .innerlabel{
                  margin: 0 auto;
                  width: 30px*$Ratio; // 宽度取font-size同值，限制一行一个字
                  font-size: 30px*$Ratio;
                  font-weight: 500;
                  color: #FFFFFF;
                  line-height: 35px*$Ratio;
                }
              }
              .products{
                width: rem(1180);
                min-height: rem(160);
                padding: rem(37) rem(25);
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                .product{
                  font-size: 28px*$Ratio;
                  color: #000000;
                  display: flex;
                  flex-direction: row;
                  .goodsName{
                    &:hover {
                      color: #ACC7E0;
                    }
                  }
                  
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $Screen_small) {
  @include styling($Ratio_small);
}
@media screen and (min-width: $Screen_medium_start) and (max-width: $Screen_medium_end) {
  @include styling($Ratio_medium);
}
@media screen and (min-width: $Screen_large) {
  @include styling($Ratio_large);
}