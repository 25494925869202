// 全局使用的sass变量和函数，
// config/webpack.config.js文件 找到exclude: sassModuleRegex 
// 使用 sass-resources-loader 依赖引入
$Header_height: 85px;
$Ratio_small: 0.55;
$Ratio_medium: 0.6;
$Ratio_large: 0.65;

$Screen_small: 1280px;
$Screen_medium_start: 1281px;
$Screen_medium_end: 1679px;
$Screen_large: 1680px;

@function vw($px) {
  @return ($px / 1920) * 100vw;
}

@function vh($px) {
  @return ($px / 1080) * 100vh;
}

@function vh700($px) {
  @return ($px / 700) * 100vh;
}



// // PX 转 rem
// @function px2Rem($px, $base-font-size: 19.2px) {
//   @if (unitless($px)) { //有无单位
//     @return ($px / 19.2) * 1rem;
//   } @else if (unit($px) == em) {
//     @return $px;
//   }
//   @return ($px / $base-font-size) * 1rem;
// }

// 屏幕在480px到768之间（主要是手机屏幕）
// 屏幕在768px到1023之间（小屏幕-pad）
// 屏幕在1024px到1199之间（中屏幕电脑）
// 屏幕大于1200排序（大屏幕电脑）

// PC端************
// 按屏幕宽度大小排序（主流：1024 1280 1440 1920）
// 分辨率   比例 | 设备尺寸
// 1024*600            （常见8、9寸电脑使用)
// 1024*768            （常见10.4、12.1、14.1、15寸电脑使用）
// 1280*1024            (常见14.1、15寸电脑使用）
// 1280*800            （常见10.8、12.1、15.4寸电脑使用）
// 1280*854            （不常见)
// 1366*768            （常见15.2寸电脑使用)（主流）
// 1440*1050            (常见15、16.1寸电脑使用)
// 1440*900            （苹果17寸电脑)
// 1600 * 900            (非主流)
// 1600*1200            (常见15、16.1寸电脑使用)
// 1600*1024            (不常见)
// 1680*1050            (常见15.4、20寸电脑使见)
// 1920*1080            (主流)
// 1920*1200            (常见20寸电脑使见)


// rem 单位换算：定为 19.20px 只是方便运算，1920px-19.20px、1080-10.px、1080px-108px，如此类推
$vw_fontsize: 19.20; // 设计稿(1920*1080)根元素大小基准值
@function rem($px) {
    // @return if($px <= 1, 1px, ($px / $vw_fontsize ) * 1rem);
    @return ($px / $vw_fontsize ) * 1rem;
}

// 根元素大小使用 vw 单位
$vw_design: 1920;
html {
    font-size: ($vw_fontsize / $vw_design) * 100vw; 
    // 同时，通过Media Queries 限制根元素最大最小值
    @media screen and (max-width: 1280px) {
        font-size: 12.80px;
    }
    @media screen and (min-width: 1920px) {
        font-size: 19.20px;
    }
}

// body 也增加最大最小宽度限制，避免默认100%宽度的 block 元素跟随 body 而过大过小
body {
    max-width: 1920px;
    // min-width: 1080px;
}
@mixin styling($Ratio){
  .Sibu_DevHistory{
    width: 100%;
    height: calc(100vh - #{rem(85)});
    overflow: hidden;
    .content{
      width: fit-content;
      height: rem(650);
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      .arrow{
        width: rem(55);
        height: rem(55);
        cursor: pointer;
        background-color: transparent;
        background-image: url('~@images/About/DevHistory/page_next.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
      .arrow_left{
        background-image: url('~@images/About/DevHistory/page_prev.png');
      }
      .arrow_right{
        background-image: url('~@images/About/DevHistory/page_next.png');
      }
      .arrow_left_disable{
        background-image: url('~@images/About/DevHistory/page_prev_disable.png');
      }
      .arrow_right_disable{
        background-image: url('~@images/About/DevHistory/page_next_disable.png');
      }
      .HistoryList{
        width: rem(1330);
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        overflow: hidden;
        margin: 0 rem(66);
        .invisible{
          visibility: hidden;
        }
        .deeds_item{
          width: rem(190);
          height: rem(24);
          background-size: 100% 100%;
          background-repeat: no-repeat;
          // 每一块 rem(190) 且不能缩放
          flex-basis: rem(190);
          flex-shrink: 0;
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          &:nth-child(odd){
            background-image: url('~@images/About/DevHistory/rect_lightgray.png');
            .date{
              position: absolute;
              top: rem(24+7);
            }
            .deeds{
              position: absolute;
              bottom: rem(24+33);
            }
            .tag{
              border: transparent rem(20) solid;
              border-top-color: #D0657C;
              position:absolute;
              bottom: rem(-40);
              left: 50%;
              transform: translaTeX(-50%);
            }
          }
          &:nth-child(even){
            background-image: url('~@images/About/DevHistory/rect_darkgray.png');
            .date{
              position: absolute;
              bottom: rem(24+7);
            }
            .deeds{
              position: absolute;
              top: rem(24+33);
            }
            .tag{
              border: transparent rem(20) solid;
              border-bottom-color: #F4BECA;
              position:absolute;  
              top: rem(-40);
              left: 50%;
              transform: translaTeX(-50%);
            }
          }
    
          .deeds{
            width: rem(180);
            max-height: rem(280);
            background: linear-gradient(0deg, #D0657C 0%, #F4BECA 100%);
            border-radius: rem(3);
    
            font-size: 22px*$Ratio;
            font-family: PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 30px*$Ratio;
            padding: rem(12);
            text-align: center;
            
            .text{
              width: 100%;
              max-height: rem(256);
              overflow: hidden;
            }
            .tag{
              content:"";
              width:0;
              height:0;
            }
            .img{
              max-width: 100%;
            }
          }
          .date{
            font-size: 24px*$Ratio;
            font-weight: 600;
            color: #000000;
            line-height: 30px*$Ratio;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $Screen_small) {
  @include styling($Ratio_small);
}
@media screen and (min-width: $Screen_medium_start) and (max-width: $Screen_medium_end) {
  @include styling($Ratio_medium);
}
@media screen and (min-width: $Screen_large) {
  @include styling($Ratio_large);
}