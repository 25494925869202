// 全局使用的sass变量和函数，
// config/webpack.config.js文件 找到exclude: sassModuleRegex 
// 使用 sass-resources-loader 依赖引入
$Header_height: 85px;
$Ratio_small: 0.55;
$Ratio_medium: 0.6;
$Ratio_large: 0.65;

$Screen_small: 1280px;
$Screen_medium_start: 1281px;
$Screen_medium_end: 1679px;
$Screen_large: 1680px;

@function vw($px) {
  @return ($px / 1920) * 100vw;
}

@function vh($px) {
  @return ($px / 1080) * 100vh;
}

@function vh700($px) {
  @return ($px / 700) * 100vh;
}



// // PX 转 rem
// @function px2Rem($px, $base-font-size: 19.2px) {
//   @if (unitless($px)) { //有无单位
//     @return ($px / 19.2) * 1rem;
//   } @else if (unit($px) == em) {
//     @return $px;
//   }
//   @return ($px / $base-font-size) * 1rem;
// }

// 屏幕在480px到768之间（主要是手机屏幕）
// 屏幕在768px到1023之间（小屏幕-pad）
// 屏幕在1024px到1199之间（中屏幕电脑）
// 屏幕大于1200排序（大屏幕电脑）

// PC端************
// 按屏幕宽度大小排序（主流：1024 1280 1440 1920）
// 分辨率   比例 | 设备尺寸
// 1024*600            （常见8、9寸电脑使用)
// 1024*768            （常见10.4、12.1、14.1、15寸电脑使用）
// 1280*1024            (常见14.1、15寸电脑使用）
// 1280*800            （常见10.8、12.1、15.4寸电脑使用）
// 1280*854            （不常见)
// 1366*768            （常见15.2寸电脑使用)（主流）
// 1440*1050            (常见15、16.1寸电脑使用)
// 1440*900            （苹果17寸电脑)
// 1600 * 900            (非主流)
// 1600*1200            (常见15、16.1寸电脑使用)
// 1600*1024            (不常见)
// 1680*1050            (常见15.4、20寸电脑使见)
// 1920*1080            (主流)
// 1920*1200            (常见20寸电脑使见)


// rem 单位换算：定为 19.20px 只是方便运算，1920px-19.20px、1080-10.px、1080px-108px，如此类推
$vw_fontsize: 19.20; // 设计稿(1920*1080)根元素大小基准值
@function rem($px) {
    // @return if($px <= 1, 1px, ($px / $vw_fontsize ) * 1rem);
    @return ($px / $vw_fontsize ) * 1rem;
}

// 根元素大小使用 vw 单位
$vw_design: 1920;
html {
    font-size: ($vw_fontsize / $vw_design) * 100vw; 
    // 同时，通过Media Queries 限制根元素最大最小值
    @media screen and (max-width: 1280px) {
        font-size: 12.80px;
    }
    @media screen and (min-width: 1920px) {
        font-size: 19.20px;
    }
}

// body 也增加最大最小宽度限制，避免默认100%宽度的 block 元素跟随 body 而过大过小
body {
    max-width: 1920px;
    // min-width: 1080px;
}
@mixin styling($Ratio){
  .Product_Detail{
    .content{
      padding-top: 85px*$Ratio;
      .BreadcrumbData{
        width: rem(1325);
        min-width: 1000px;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 70px*$Ratio auto 32px*$Ratio;
        padding-left: rem(54);
        .colorBlock{
          width: 12px*$Ratio;
          height: 50px*$Ratio;
          background: #BE3D59;
          margin-right: 23px*$Ratio;
        }
        .item{
          display: flex;
          flex-direction: row;
          align-items: center;
          &:last-child{
            flex: 1;
          }
          .name{
            font-size: 36px*$Ratio;
            color: #8C8C8C;
          }
          .name:hover{
            color: #ACC7E0;
          }
          .active{
            font-weight: 500;
            color: #000000;
          }
          .arrow{
            width: 17px*$Ratio;
            height: 30px*$Ratio;
            margin: auto 26px*$Ratio;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-image: url('~@images/BrandCenter/Detail/arrow_gray.png');
          }
        }
      }
      .product_info{
        width: rem(1325);
        min-width: 1000px;
        height: fit-content;
        background: #F3F3F3;
        border-radius: 3px*$Ratio;
        margin: rem(30) auto rem(50);
        padding: rem(50) rem(50) 0;
        display: flex;
        flex-direction: row;
        .left{
          display: flex;
          flex-direction: column;
          align-items: center;
          .bigImg{
            width: rem(530);
            height: rem(530);
            background: #FFFFFF;
            border-radius: 3px;
            img{
              width: 100%;
              height: 100%;
            }
          }
          .Products_swiper{
            margin-top: 49px;
            width: rem(478 + 10); // 112*4+10*3
            display: flex;
            flex-direction: row;
            position:relative;
            .swiper-button-prev-777, .swiper-button-next-777{
              width: rem(30);
              height: rem(112);
              border-radius: 3px;
              line-height: rem(112);
              &:hover{
                background-color: #dedede;
              }
              .button{
                width: rem(15);
                height: rem(34);
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-repeat: no-repeat;
                background-size: 100% 100%;
              }
              .gggg_prev_icon{
                background-image: url('~@images/BrandCenter/Detail/detail_left.png');
              }
              .gggg_next_icon{
                background-image: url('~@images/BrandCenter/Detail/detail_right.png');
              }
            }
            .swiper-button-prev-777{
              position: absolute;
              left: rem(-40);
              top: 50%;
              transform: translateY(-50%);
            }
            .swiper-button-next-777{
              position: absolute;
              right: rem(-40);
              top: 50%;
              transform: translateY(-50%);
            }
            .swiper-button-disabled{
              opacity: 0.2;
            }

            .swiper-container{
              height: rem(112);
              .swiper-slide{
                width: rem(112)!important;
                margin-right: rem(10);
                // !important 很重要 为了覆盖antd组件的style.width,
                // 此处的作用是：当视口宽高发生变化时，需要重置swiper-slide的宽度，否则会发生渲染错误。
              }
            }
            
            .product_item{
              width: rem(112);
              display: flex;
              flex-direction: column;
              align-items: center;
              cursor: pointer;
              .active{
                border: hotpink 1px solid;
              }
              .img{
                width: rem(112);
                height: rem(112);
                border-radius: 3px;
                background: #FFFFFF;
                img{
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }
        .right{
          display: flex;
          flex-direction: column;
          margin-left: 50px;
          width: 630px;
          .name{
            font-size: 40px*$Ratio;
            font-family: PingFang SC;
            font-weight: 500;
            color: #000000;
            margin-bottom: 20px;
          }
          .text{
            min-height: fit-content;
            font-size: 24px*$Ratio;
            color: #8C8C8C;
          }
          .label{
            font-size: 24px*$Ratio;
            font-family: PingFang SC;
            font-weight: 500;
            color: #000000;
          }
          .desc{
            margin-top: 40px;
            .text{
              margin-top: 15px;
            }
          }
          .price{
            margin-top: 40px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            .text{
              font-size: 40px*$Ratio;
              font-family: PingFang SC;
              font-weight: 500;
              color: #BE3D59;
              margin-left: 16px;
            }
          }
          .scaleList{
            margin-top: 20px;
            .options{
              margin-top: 15px;
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              justify-content: flex-start;
              align-items: center;
              cursor: pointer;
              .option{
                min-width: 160px;
                width: fit-content;
                background: #F3F3F3;
                border: 1px solid #8C8C8C;
                border-radius: 3px;
                font-size: 24px*$Ratio;
                font-weight: 500;
                color: #8C8C8C;
                line-height: 36px*$Ratio;
                text-align: center;
                margin-right: rem(20);
                margin-bottom: 20px;
                padding: 8px 15px;
              }
              .active{
                background: #BE3D59;
                border: none;
                color: #F3F3F3;
              }
            }
          }
        }
      }
      .richText{
        width: rem(1325);
        height: auto;
        margin:  50px*$Ratio auto 200px*$Ratio;
        img{
          max-width: 100%;
        }
      }
    } 
  }
}

@media screen and (max-width: $Screen_small) {
  @include styling($Ratio_small);
}
@media screen and (min-width: $Screen_medium_start) and (max-width: $Screen_medium_end) {
  @include styling($Ratio_medium);
}
@media screen and (min-width: $Screen_large) {
  @include styling($Ratio_large);
}