// 全局使用的sass变量和函数，
// config/webpack.config.js文件 找到exclude: sassModuleRegex 
// 使用 sass-resources-loader 依赖引入
$Header_height: 85px;
$Ratio_small: 0.55;
$Ratio_medium: 0.6;
$Ratio_large: 0.65;

$Screen_small: 1280px;
$Screen_medium_start: 1281px;
$Screen_medium_end: 1679px;
$Screen_large: 1680px;

@function vw($px) {
  @return ($px / 1920) * 100vw;
}

@function vh($px) {
  @return ($px / 1080) * 100vh;
}

@function vh700($px) {
  @return ($px / 700) * 100vh;
}



// // PX 转 rem
// @function px2Rem($px, $base-font-size: 19.2px) {
//   @if (unitless($px)) { //有无单位
//     @return ($px / 19.2) * 1rem;
//   } @else if (unit($px) == em) {
//     @return $px;
//   }
//   @return ($px / $base-font-size) * 1rem;
// }

// 屏幕在480px到768之间（主要是手机屏幕）
// 屏幕在768px到1023之间（小屏幕-pad）
// 屏幕在1024px到1199之间（中屏幕电脑）
// 屏幕大于1200排序（大屏幕电脑）

// PC端************
// 按屏幕宽度大小排序（主流：1024 1280 1440 1920）
// 分辨率   比例 | 设备尺寸
// 1024*600            （常见8、9寸电脑使用)
// 1024*768            （常见10.4、12.1、14.1、15寸电脑使用）
// 1280*1024            (常见14.1、15寸电脑使用）
// 1280*800            （常见10.8、12.1、15.4寸电脑使用）
// 1280*854            （不常见)
// 1366*768            （常见15.2寸电脑使用)（主流）
// 1440*1050            (常见15、16.1寸电脑使用)
// 1440*900            （苹果17寸电脑)
// 1600 * 900            (非主流)
// 1600*1200            (常见15、16.1寸电脑使用)
// 1600*1024            (不常见)
// 1680*1050            (常见15.4、20寸电脑使见)
// 1920*1080            (主流)
// 1920*1200            (常见20寸电脑使见)


// rem 单位换算：定为 19.20px 只是方便运算，1920px-19.20px、1080-10.px、1080px-108px，如此类推
$vw_fontsize: 19.20; // 设计稿(1920*1080)根元素大小基准值
@function rem($px) {
    // @return if($px <= 1, 1px, ($px / $vw_fontsize ) * 1rem);
    @return ($px / $vw_fontsize ) * 1rem;
}

// 根元素大小使用 vw 单位
$vw_design: 1920;
html {
    font-size: ($vw_fontsize / $vw_design) * 100vw; 
    // 同时，通过Media Queries 限制根元素最大最小值
    @media screen and (max-width: 1280px) {
        font-size: 12.80px;
    }
    @media screen and (min-width: 1920px) {
        font-size: 19.20px;
    }
}

// body 也增加最大最小宽度限制，避免默认100%宽度的 block 元素跟随 body 而过大过小
body {
    max-width: 1920px;
    // min-width: 1080px;
}
@mixin styling($Ratio){
  .Video_slide{
    width: 100%;
    height: 100%;
    .main{
      margin: 0 auto;
      width: 100%;
      display: flex;
      flex-direction: column;
      height: 100%;
      align-items: center;
      // justify-content: center;
      .center{
        display: flex;
        flex-direction: column;
        .videoList{
          @media screen and (max-height: 700px) {
            width: 860px;
            height: 365px;
          }
          width: vh(1325);
          height: vh(560);
          display: flex;
          flex-direction: row;
          margin: 0 auto;
          flex-wrap: wrap;
          justify-content: space-between;
          overflow: hidden;
          .invisible{
            visibility: hidden;
          }
          .video_item{
            @media screen and (max-height: 700px) {
              width: 276px;
              height: 155px;
              margin-top: vh(80);
            }
            width: vh(425);
            height: vh(240);
            background: #FFFFFF;
            box-shadow: 0 vh(11) vh(35) 0 rgba(0, 0, 0, 0.12);
            border-radius: vh(10);
            // margin-right: vh(25);
            margin-top: vh(80);
            position: relative;
            overflow: hidden;
            cursor: pointer;
            // &:nth-child(3n+3){
            //   margin-right: 0;
            // }
            &:nth-child(-n+3){
              margin-top: 0;
            }
            .video_mask{
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-color: rgba(0, 0, 0, 0.4);
              .play_button{
                @media screen and (max-height: 700px) {
                  width: 48px;
                  height: 48px;
                }
                width: vh(75);
                height: vh(75);
                background-color: transparent;
                background-size: 100% 100%;
                background-image: url('~@images/Home/video_play@2x.png');
                background-repeat: no-repeat;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }

            }
            .video{
              width: 100%;
              height: 100%;
            }
          }

        }
        .seeMore_button{
          @media screen and (max-height: 700px) {
            width: 212px;
            height: 38px;
            line-height: 38px;
            border-radius: 19px;
          }
          width: vh(328);
          height: vh(58);
          background: #BE3D59;
          border-radius: vh(29);
          text-align: center;
          font-size: 24px*$Ratio;
          line-height: vh(58);
          color: #FFFFFF;
          margin: vh(45) auto;
          cursor: pointer;
        }
      }
    }
  }
}

@media screen and (max-width: $Screen_small) {
  @include styling($Ratio_small);
}
@media screen and (min-width: $Screen_medium_start) and (max-width: $Screen_medium_end) {
  @include styling($Ratio_medium);
}
@media screen and (min-width: $Screen_large) {
  @include styling($Ratio_large);
}