// 全局使用的sass变量和函数，
// config/webpack.config.js文件 找到exclude: sassModuleRegex 
// 使用 sass-resources-loader 依赖引入
$Header_height: 85px;
$Ratio_small: 0.55;
$Ratio_medium: 0.6;
$Ratio_large: 0.65;

$Screen_small: 1280px;
$Screen_medium_start: 1281px;
$Screen_medium_end: 1679px;
$Screen_large: 1680px;

@function vw($px) {
  @return ($px / 1920) * 100vw;
}

@function vh($px) {
  @return ($px / 1080) * 100vh;
}

@function vh700($px) {
  @return ($px / 700) * 100vh;
}



// // PX 转 rem
// @function px2Rem($px, $base-font-size: 19.2px) {
//   @if (unitless($px)) { //有无单位
//     @return ($px / 19.2) * 1rem;
//   } @else if (unit($px) == em) {
//     @return $px;
//   }
//   @return ($px / $base-font-size) * 1rem;
// }

// 屏幕在480px到768之间（主要是手机屏幕）
// 屏幕在768px到1023之间（小屏幕-pad）
// 屏幕在1024px到1199之间（中屏幕电脑）
// 屏幕大于1200排序（大屏幕电脑）

// PC端************
// 按屏幕宽度大小排序（主流：1024 1280 1440 1920）
// 分辨率   比例 | 设备尺寸
// 1024*600            （常见8、9寸电脑使用)
// 1024*768            （常见10.4、12.1、14.1、15寸电脑使用）
// 1280*1024            (常见14.1、15寸电脑使用）
// 1280*800            （常见10.8、12.1、15.4寸电脑使用）
// 1280*854            （不常见)
// 1366*768            （常见15.2寸电脑使用)（主流）
// 1440*1050            (常见15、16.1寸电脑使用)
// 1440*900            （苹果17寸电脑)
// 1600 * 900            (非主流)
// 1600*1200            (常见15、16.1寸电脑使用)
// 1600*1024            (不常见)
// 1680*1050            (常见15.4、20寸电脑使见)
// 1920*1080            (主流)
// 1920*1200            (常见20寸电脑使见)


// rem 单位换算：定为 19.20px 只是方便运算，1920px-19.20px、1080-10.px、1080px-108px，如此类推
$vw_fontsize: 19.20; // 设计稿(1920*1080)根元素大小基准值
@function rem($px) {
    // @return if($px <= 1, 1px, ($px / $vw_fontsize ) * 1rem);
    @return ($px / $vw_fontsize ) * 1rem;
}

// 根元素大小使用 vw 单位
$vw_design: 1920;
html {
    font-size: ($vw_fontsize / $vw_design) * 100vw; 
    // 同时，通过Media Queries 限制根元素最大最小值
    @media screen and (max-width: 1280px) {
        font-size: 12.80px;
    }
    @media screen and (min-width: 1920px) {
        font-size: 19.20px;
    }
}

// body 也增加最大最小宽度限制，避免默认100%宽度的 block 元素跟随 body 而过大过小
body {
    max-width: 1920px;
    // min-width: 1080px;
}
@mixin styling($Ratio){
  .VideoCenter{
    position: relative;
    min-height: 100vh;
    .content{
      padding-top: rem(85+70);
      padding-bottom: rem(125);
      .main_area{
        width: rem(1720);
        height: fit-content;
        background: #FFFFFF;
        border-radius: rem(3);
        margin: 0 auto rem(89);
        .top_section{
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          margin: 0 auto;
          .player{
            width: rem(1275);
            height: rem(717);
            border-radius: rem(3);
          }
          .playList{
            width: rem(415);
            height: rem(717);
            background: #F3F3F3;
            border-radius: rem(3);
            overflow-x: hidden;
            overflow-y: auto;
            padding: rem(22) rem(28);
            .video{

              display: flex;
              flex-direction: row;
              align-items: flex-start;
              margin-top: rem(34);
              &:first-child{
                margin-top: 0;
              }
              .box1{
                position: relative;
                width: rem(190);
                height: rem(107);
                .duration{
                  position: absolute;
                  bottom: rem(10);
                  right: rem(10);
                  font-size: 22px*$Ratio;
                  line-height: 22px*$Ratio;
                  color: #FFFFFF;
                }
                .img{
                  width: 100%;
                  height: 100%;
                  border-radius: rem(5);
                  object-fit: fill;
                }
              }

              .box2{
                width: rem(160);
                height: rem(107);
                display: flex;
                flex-direction: column;
                margin-left: rem(9);
                justify-content: space-between;
                font-size: 22px*$Ratio;
                .title{
                  width: 100%;
                  color: #000000;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                }
                .volumn{
                  color: #8C8C8C;
                  align-self: flex-end;
                }
              }
              
            }
          }

        }
        .bot_section{
          width: rem(1720);
          height: fit-content;
          background: #E5E5E5;
          border-radius: 3px;
          padding: 30px 40px 15px;

          .title{
            font-size: 36px*$Ratio;
            font-weight: 600;
            color: #000000;
          }
          .others{
            margin-top: 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
            .volume{
              font-size: 24px*$Ratio;
              color: #000000;
            }
            .vertical_line{
              width: 1px;
              height: 24px*$Ratio;
              background: #000000;
              margin-left: 20px;
              margin-right: 20px;
            }
            .intro{
              display: flex;
              flex-direction: row;
              align-items: center;
              .text{
                font-size: 24px*$Ratio;
                color: #000000;
                margin-right: 7px;
              }
              .icon{
                width: 24px*$Ratio;
                height: 13px*$Ratio;
                font-size: 15px*$Ratio;
                transition: all 0.5s;
                background-size: 100% 100%;
                background-image: url('~@images/Video/up.png');
                transform: rotate(180deg);
                background-repeat: no-repeat;
              }
              .icon_active{
                transform: rotate(0);
              }
            }
            .tag{
              margin-left: 20px;
              width: fit-content;
              height: 50px*$Ratio;
              background: #F3F3F3;
              border-radius: 3px;
              padding: 0 28px;
              font-size: 24px*$Ratio;
              color: #000000;
              text-align: center;
              line-height: 50px*$Ratio;
            }
          }
          .intro_detail{
            height: fit-content;
            font-size: 24px*$Ratio;
            color: #000000;
            padding-bottom: 20px;
            margin-top: 23px;
            animation: opacity 1s;
            @keyframes opacity {
              from { opacity: 0.3 }
              to { opacity: 1 }
            }
          }
        }
      }

      .swipers{
        width: rem(1720);
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        .category{
          margin-bottom: rem(80);
          .category_name{
            font-size: 28px*$Ratio;
            font-weight: 600;
            color: #000000;
            margin-bottom: rem(21);
            margin-left: rem(7);
            line-height: 35px*$Ratio;
          }
          .Products_swiper{
            margin: 0 rem(30);
            display: flex;
            flex-direction: row;
            position:relative;
            .swiper-button-prev, .swiper-button-next{
              width: rem(60);
              height: rem(60);
              margin: 0;
              background-size: 100% 100%;
              background-repeat: no-repeat;
              pointer-events: auto; // 防止点击穿透到下层的video_item
            }
            .swiper-button-prev{
              left: rem(-30);
              top: rem(59);// 178*0.5-30
              background-image: url('~@images/Video/video_left.png');
            }
            .swiper-button-next{
              right: rem(-30);
              top: rem(59);// 178*0.5-30
              background-image: url('~@images/Video/video_right.png');
            }
            .swiper-button-disabled{
              opacity: $Ratio;
            }
            .swiper-container{
              width: rem(1680);
              .swiper-slide{
                width: rem(315)!important;
                // !important 很重要 为了覆盖antd组件的style.width,
                // 此处的作用是：当视口宽高发生变化时，需要重置swiper-slide的宽度，否则会发生渲染错误。
              }
            }
            .product_item{
              width: rem(315);
              display: flex;
              flex-direction: column;
              align-items: center;
              cursor: pointer;
              .img{
                width: rem(315);
                height: rem(178);
                position: relative;
                .img{
                  width: 100%;
                  height: 100%;
                  border-radius: rem(5);
                  object-fit: fill;
                }
                .duration{
                  position: absolute;
                  bottom: rem(15);
                  right: rem(15);
                  font-size: 24px*$Ratio;
                  line-height: 24px*$Ratio;
                  color: #FFFFFF;
                }
              }
              .box{
                display: flex;
                flex-direction: column;
                align-items: center;
                .title{
                  font-size: 24px*$Ratio;
                  color: #000000;
                  line-height: 35px*$Ratio;
                  margin-top: 17px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 1;
                  -webkit-box-orient: vertical;
                }
              }
            }
          }
        }
      }
    }
  }
}


@media screen and (max-width: $Screen_small) {
  @include styling($Ratio_small);
}
@media screen and (min-width: $Screen_medium_start) and (max-width: $Screen_medium_end) {
  @include styling($Ratio_medium);
}
@media screen and (min-width: $Screen_large) {
  @include styling($Ratio_large);
}