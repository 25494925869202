// 全局使用的sass变量和函数，
// config/webpack.config.js文件 找到exclude: sassModuleRegex 
// 使用 sass-resources-loader 依赖引入
$Header_height: 85px;
$Ratio_small: 0.55;
$Ratio_medium: 0.6;
$Ratio_large: 0.65;

$Screen_small: 1280px;
$Screen_medium_start: 1281px;
$Screen_medium_end: 1679px;
$Screen_large: 1680px;

@function vw($px) {
  @return ($px / 1920) * 100vw;
}

@function vh($px) {
  @return ($px / 1080) * 100vh;
}

@function vh700($px) {
  @return ($px / 700) * 100vh;
}



// // PX 转 rem
// @function px2Rem($px, $base-font-size: 19.2px) {
//   @if (unitless($px)) { //有无单位
//     @return ($px / 19.2) * 1rem;
//   } @else if (unit($px) == em) {
//     @return $px;
//   }
//   @return ($px / $base-font-size) * 1rem;
// }

// 屏幕在480px到768之间（主要是手机屏幕）
// 屏幕在768px到1023之间（小屏幕-pad）
// 屏幕在1024px到1199之间（中屏幕电脑）
// 屏幕大于1200排序（大屏幕电脑）

// PC端************
// 按屏幕宽度大小排序（主流：1024 1280 1440 1920）
// 分辨率   比例 | 设备尺寸
// 1024*600            （常见8、9寸电脑使用)
// 1024*768            （常见10.4、12.1、14.1、15寸电脑使用）
// 1280*1024            (常见14.1、15寸电脑使用）
// 1280*800            （常见10.8、12.1、15.4寸电脑使用）
// 1280*854            （不常见)
// 1366*768            （常见15.2寸电脑使用)（主流）
// 1440*1050            (常见15、16.1寸电脑使用)
// 1440*900            （苹果17寸电脑)
// 1600 * 900            (非主流)
// 1600*1200            (常见15、16.1寸电脑使用)
// 1600*1024            (不常见)
// 1680*1050            (常见15.4、20寸电脑使见)
// 1920*1080            (主流)
// 1920*1200            (常见20寸电脑使见)


// rem 单位换算：定为 19.20px 只是方便运算，1920px-19.20px、1080-10.px、1080px-108px，如此类推
$vw_fontsize: 19.20; // 设计稿(1920*1080)根元素大小基准值
@function rem($px) {
    // @return if($px <= 1, 1px, ($px / $vw_fontsize ) * 1rem);
    @return ($px / $vw_fontsize ) * 1rem;
}

// 根元素大小使用 vw 单位
$vw_design: 1920;
html {
    font-size: ($vw_fontsize / $vw_design) * 100vw; 
    // 同时，通过Media Queries 限制根元素最大最小值
    @media screen and (max-width: 1280px) {
        font-size: 12.80px;
    }
    @media screen and (min-width: 1920px) {
        font-size: 19.20px;
    }
}

// body 也增加最大最小宽度限制，避免默认100%宽度的 block 元素跟随 body 而过大过小
body {
    max-width: 1920px;
    // min-width: 1080px;
}
@mixin styling($Ratio){
  .Sibu_Honor{
    width: 100%;
    height: calc(100vh - 85px*#{$Ratio});
    overflow: hidden;
    .content{
      width: fit-content;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      .honorList{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        @media screen and (max-height: 700px) {
          width: 860px;
          height: 454px;
        }
        width: vh(1330);
        height: vh(700);
        margin: 0 auto;
        overflow: hidden;
        .honor_item{
          @media screen and (max-height: 700px) {
            width: 204px;
            height: 142px;
            margin-right: 14px;
            margin-bottom: 14px;
          }
          display: flex;
          flex-direction: column;
          align-items: center;
          width: vh(315);
          height: vh(220);
          margin-right: vh(20);
          margin-bottom: vh(20);
          overflow: hidden;
          &:nth-child(4n+4){
            margin-right: 0;
          }
          &:nth-last-child(-n+4){
            margin-bottom: 0;
          }
          .honor_img{
            @media screen and (max-height: 700px) {
              height: 116px;
            }
            width: 100%;
            height: vh(180);
            border-radius: vh(3);
          }
          .honor_name{
            @media screen and (max-height: 700px) {
              margin-top: 5px;
              font-size: 14px;
              height: 21px;
            }
            width: 100%;
            margin-top: vh(9);
            // height: vh(30);
            font-size: 21px*$Ratio;
            color: #000000;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }
        }
      }

      .pageOpts{
        width: fit-content;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        .opt{
          margin-top: vh(40);
          margin-right: vh(140);
          text-align: center;
          color: #BE3D59;
          font-weight: 800;
          width: vh(36);
          height: vh(36);
          background-color: transparent;
          background-size: 100% 100%;
          &:last-child{
            margin-right: 0;
          }
        }
        .partner_next{
          background-image: url('~@images/Home/partner_next.png');
        }
        .partner_prev{
          background-image: url('~@images/Home/partner_prev.png');
        }
      }
    }
  }
}

@media screen and (max-width: $Screen_small) {
  @include styling($Ratio_small);
}
@media screen and (min-width: $Screen_medium_start) and (max-width: $Screen_medium_end) {
  @include styling($Ratio_medium);
}
@media screen and (min-width: $Screen_large) {
  @include styling($Ratio_large);
}